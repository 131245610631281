<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box mt20px">
      <ayl-main title="基本信息" :isCollapse="false">
        <div slot="main" class="p24px">
          <div class="contents">
            <span>车辆类型：</span>
            <span>{{ formData.sanitationCarManageVO.carTypeDesc ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>车牌号：</span>
            <span>{{ formData.sanitationCarManageVO.plateNumber ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>权属类型：</span>
            <span>{{ formData.sanitationCarManageVO.belongTypeDesc }}</span>
          </div>
          <div class="contents">
            <span>所属标段：</span>
            <span>{{ formData.sanitationCarManageVO.bidName ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>动力类型：</span>
            <span>{{ formData.sanitationCarManageVO.powerTypeDesc ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>发动机号：</span>
            <span>{{ formData.sanitationCarManageVO.engineNumber ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>所在项目组：</span>
            <span>{{ formData.sanitationCarManageVO.projectGroupId ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>车架号：</span>
            <span>{{ formData.sanitationCarManageVO.vin ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>使用状态：</span>
            <span>{{ formData.sanitationCarManageVO.carStatusDesc ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>所属企业：</span>
            <span>{{ formData.sanitationCarManageVO.companyName ||'- -' }}</span>
          </div>
          <div class="contents" style="width: 100%">
            <span>车辆照片：</span>
            <div
              style="display:inline-block; width: 160px;height: 160px;vertical-align:top; border-radius: 4px"
              v-if="formData.sanitationCarManageVO.carImageUrls !== null"
            >
              <!-- <el-image
                :src="formData.sanitationCarManageVO.carImageUrls"
                v-image-preview
                fit="cover"
                style="width: 100%;height: 100%;border-radius: 4px"
              ></el-image>-->
              <ayl-image v-model="formData.sanitationCarManageVO.carImageUrls" />
            </div>
            <span v-else>{{ '- -' }}</span>
          </div>
        </div>
      </ayl-main>

      <ayl-main title="油箱参数" :isCollapse="false">
        <div slot="main" class="p24px">
          <div class="contents">
            <span>油量监测：</span>
            <span>{{ optionsValue12(formData.sanitationCarManageVO.hasFuel)}}</span>
          </div>
          <div class="contents" v-if="formData.sanitationCarManageVO.hasFuel === 1">
            <span>油箱形状：</span>
            <span>{{ fuelTankType(formData.carFuelInfoVO.fuelTankType) }}</span>
          </div>
          <div v-if= "formData.sanitationCarManageVO.hasFuel === 1">
            <div class="contents">
              <span>长(cm)：</span>
              <span>{{ formData.carFuelInfoVO.length ||'- -'}}</span>
            </div>
            <div class="contents" v-if= "formData.carFuelInfoVO.fuelTankType === 0">
              <span>宽(cm)：</span>
              <span>{{ formData.carFuelInfoVO.width ||'- -'}}</span>
            </div>
            <div class="contents" v-if= "formData.carFuelInfoVO.fuelTankType === 0">
              <span>高(cm)：</span>
              <span>{{ formData.carFuelInfoVO.height ||'- -'}}</span>
            </div>
            <div class="contents" v-if= "formData.carFuelInfoVO.fuelTankType === 1 && formData.carFuelInfoVO.fuelTankType === 2">
              <span>直径(cm)：</span>
              <span>{{ formData.carFuelInfoVO.diameter ||'- -'}}</span>
            </div>
            <div class="contents" v-if= "formData.carFuelInfoVO.fuelTankType === 3">
              <span>长直径(cm)：</span>
              <span>{{ formData.carFuelInfoVO.longDiameter ||'- -'}}</span>
            </div>
            <div class="contents" v-if= "formData.carFuelInfoVO.fuelTankType === 3">
              <span>短直径(cm)：</span>
              <span>{{ formData.carFuelInfoVO.shortDiameter ||'- -'}}</span>
            </div>
            <div class="contents" v-if= "formData.carFuelInfoVO.fuelTankType === 3">
              <span>颈高(cm)：</span>
              <span>{{ formData.carFuelInfoVO.neckHeight ||'- -'}}</span>
            </div>
            <div class="contents">
              <span>油杆长(cm)：</span>
              <span>{{ formData.carFuelInfoVO.fuelRodLength ||'- -'}}</span>
            </div>
            <div class="contents">
              <span>油箱厚度(cm)：</span>
              <span>{{ formData.carFuelInfoVO.thickness ||'- -'}}</span>
            </div>
            <div class="contents">
              <span>最大标定值：</span>
              <span>{{ formData.carFuelInfoVO.maxCalibration ||'- -'}}</span>
            </div>
            <div class="contents" v-if= "formData.carFuelInfoVO.fuelTankType !== 3">
              <span>容积修正参数：</span>
              <span>{{ formData.carFuelInfoVO.volumeRevisionFactor ||'- -'}}</span>
            </div>
          </div>
        </div>
      </ayl-main>

      <ayl-main title="保险信息" :isCollapse="false">
        <div slot="main" class="p24px">
          <div class="contents">
            <span>保险公司：</span>
            <span>{{ formData.sanitationInsuranceInfoVO.insuranceCompany ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>保险类型：</span>
            <span>{{ formData.sanitationInsuranceInfoVO.insuranceType||'- -' }}</span>
          </div>
          <div class="contents">
            <span>保险单号：</span>
            <span>{{formData.sanitationInsuranceInfoVO.insuranceNumber||"- -"}}</span>
          </div>

          <div class="contents">
            <span>保险有效期：</span>
            <span v-if="formData.sanitationInsuranceInfoVO.startTime !== null">{{ formData.sanitationInsuranceInfoVO.startTime|str2ymd }} ~ </span>
             <span v-if="formData.sanitationInsuranceInfoVO.endTime !== null">{{ formData.sanitationInsuranceInfoVO.endTime|str2ymd }}</span>
            <span v-else>{{ "- -" }}</span>
          </div>
          <div class="contents">
            <span>购买价格：</span>
            <span>{{ formData.sanitationInsuranceInfoVO.purchasePrice || "- -" }}</span>
          </div>

          <div class="contents">
            <span>代理人：</span>
            <span>{{ formData.sanitationInsuranceInfoVO.agent ||'- -' }}</span>
          </div>
        </div>
      </ayl-main>

      <ayl-main title="行驶证信息" :isCollapse="false">
        <div slot="main" class="p24px">
          <div class="contents">
            <span>行驶证所有人：</span>
            <span>{{ formData.sanitationDrivingLicenseInfoVO.owner ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>行驶证有效期：</span>
            <span v-if="formData.sanitationDrivingLicenseInfoVO.startTime !== null">{{ formData.sanitationDrivingLicenseInfoVO.startTime|str2ymd }} ~ </span>
            <span v-if="formData.sanitationDrivingLicenseInfoVO.endTime !== null">{{ formData.sanitationDrivingLicenseInfoVO.endTime|str2ymd }}</span>
            <span v-else>{{ "- - " }}</span>
          </div>
          <div class="contents" style="width: 100%">
            <span>行驶证图片：</span>
            <span
              v-if="formData.sanitationDrivingLicenseInfoVO.driverLicenseImageKeyList !== null"
              style="display:inline-block; width: 160px;height: 160px;vertical-align:top; border-radius: 4px"
            >
            <!-- {{formData.sanitationDrivingLicenseInfoVO.driverLicenseImageUrlList}} -->
            <img :src="formData.sanitationDrivingLicenseInfoVO.driverLicenseImageUrlList" v-image-preview style="width: 100%; height: 100%;"/>
            </span>
            <span v-else>{{ "- - " }}</span>
          </div>
        </div>
      </ayl-main>

      <ayl-main title="年检信息" :isCollapse="false">
        <div slot="main" class="p24px">
          <div class="contents">
            <span>年检编号：</span>
            <span>{{formData.sanitationCarInspectVO.inspectId ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>年检日期：</span>
            <span>{{ formData.sanitationCarInspectVO.inspectDate|str2ymd}}</span>
          </div>
          <div class="contents">
            <span>到期日期：</span>
            <span>{{ formData.sanitationCarInspectVO.inspectExpire|str2ymd }}</span>
          </div>
          <div class="contents">
            <span>年检费用：</span>
            <span>{{ formData.sanitationCarInspectVO.inspectFee ||'- -' }}</span>
          </div>
        </div>
      </ayl-main>

      <ayl-main title="保养信息" :isCollapse="false">
        <div slot="main" class="p24px">
          <div class="contents">
            <span>最近保养日期：</span>
            <span>{{ formData.sanitationCarMaintainVO.lastMaintainDate|str2ymd}}</span>
          </div>
          <div class="contents">
            <span>保养周期：</span>
            <span>{{ formData.sanitationCarMaintainVO.maintainPeriod ||'- -' }}</span>
          </div>
        </div>
      </ayl-main>
      <div class="tac" style="margin-top: 40px">
        <el-button
          style="margin-left: 30px"
          @click="onClose"
        >返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: [{ name: "车辆详情" }],
      data: {},
      form: {
        name: null,
        textarea: null
      },
      rules: {},
      submitLoading: false,
      formData: {
        carFuelInfoVO: {},
        sanitationCarManageVO: {},
        sanitationCarInspectVO: {},
        sanitationCarMaintainVO: {},
        sanitationInsuranceInfoVO: {},
        sanitationDrivingLicenseInfoVO: {}
      }
    };
  },
  methods: {
    stropList(e) {
      let str = "";
      str = (e || []).join("、");
      return str;
    },
    optionsValue12(val) {
      return {0: "未启用", 1: "启用"}[val]
    },
    fuelTankType(val) {
      return {0: "长方形", 1: "圆形", 2: "D型", 3: "椭圆形"}[val]
    },
    onClose() {
      BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
    }
  },
  async mounted() {
    // this.data = await this.$api.getOilEventDetailInfo({
    //   eventType: "加油",
    //   plateNumber: this.$route.query.plateNumber,
    //   startTime: this.$route.query.startTime
    // });

    let carNub = decodeURIComponent(this.$route.query.id);
    this.formData = await this.$api_hw.vehicleManagement_getCarInfoByPlateNumber(
      {
        plateNumber: carNub
      }
    );
    if(this.formData.sanitationDrivingLicenseInfoVO.driverLicenseImageUrlList !== null) {
      this.formData.sanitationDrivingLicenseInfoVO.driverLicenseImageUrlList = this.formData.sanitationDrivingLicenseInfoVO.driverLicenseImageUrlList[0]
    }
    
  }
};
</script>
<style lang='sass' scoped>
</style>
